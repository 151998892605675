<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">修改信息</span>
      </template>

      <account-setting-general
        v-if="user.general"
        :general-data="user.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">修改密码</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'

import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import userStore from './../userStore'


export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
  },
  data() {
    return {
      user: {},
    }
  },
  setup() {
    // Register module
    if (!store.hasModule('user')) store.registerModule('user', userStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('user')) store.unregisterModule('user')
    })

    const setting = function() {
      store.dispatch('user/setting', {id: this.id}).then(res => {
        this.user = res.data.data
      })
    }

    return {
      setting,
    }
  },
  created() {
    this.setting()
  },
}
</script>
